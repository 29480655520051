import "./App.scss";
import Routes from "./routes/Routes";
import { Provider } from "react-redux";
import configureStore from 'store/configureStore';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';

export const { store } = configureStore();
if (process.env.NODE_ENV === "prod") {
  console.log = () => { };
}

let theme = createTheme({
  typography: {
    fontFamily: [
      '"Hanken Grotesk"', 'sans-serif',
    ].join(','),
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Routes />
        </Provider>
      </ThemeProvider>
    </div >
  );
}

export default App;
